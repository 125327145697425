import { Controller } from "stimulus"

###
  This Stimulus controller populates the browser's localStorage or sessionStorage
  with key-value pairs provided as a JSON object. The JSON object is expected to
  be passed to the controller via a `data-browser-storage-data-value` attribute.

  Example HTML Usage:
  <div data-controller="browser-storage"
       data-browser-storage-data-value='<%= browser_storage_data.to_json %>'>
  </div>

  Each key-value pair in the JSON object should include:
  - `key`: The key to store in the browser storage.
  - `value`: The value associated with the key.
  - `storage_type`: The type of storage ("localStorage" or "sessionStorage").
###
export default class extends Controller
  @values =
    storageData: Array

  connect: ->
    @populateStorage()

  populateStorage: ->
    storageData = @storageDataValue
  
    if storageData? and Array.isArray(storageData)
      for item in storageData
        if item?.key? and item?.value? and item?.storage_type? and item.storage_type in ['localStorage', 'sessionStorage']
          if item.storage_type is 'localStorage'
            localStorage.setItem(item.key, item.value)
          else if item.storage_type is 'sessionStorage'
            sessionStorage.setItem(item.key, item.value)