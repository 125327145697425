import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['searchPane', 'previewPane', 'previewLoader', 'previewContent', 'closePreviewButton', 'searchResultsPane']

  initialize: ->
    @searchPaneTarget.hidden = false
    @previewPaneTarget.hidden = true
    @remodalScrollPosition = 0

  showPreviewLoading: ->
    # Before we show/hide panes, get the current scroll position of the Remodal container.
    @remodalScrollPosition = $('.remodal.remodal-is-opened')[0].parentElement.scrollTop

    @searchPaneTarget.hidden = true
    @previewPaneTarget.hidden = false
    @previewLoaderTarget.hidden = false
    @previewContentTarget.hidden = true
    for closeButton in @closePreviewButtonTargets
      closeButton.hidden = true

  showLoadedPreviewContent: (event) ->
    @searchPaneTarget.hidden = true
    @previewPaneTarget.hidden = false
    @previewLoaderTarget.hidden = true
    @previewContentTarget.innerHTML = event.detail.content
    @previewContentTarget.hidden = false
    for closeButton in @closePreviewButtonTargets
      closeButton.hidden = false

  closePreview: ->
    @searchPaneTarget.hidden = false
    @previewPaneTarget.hidden = true

    # Restore the Remodal scroll position to whatever it was before the preview.
    $('.remodal.remodal-is-opened')[0].parentElement.scrollTop = @remodalScrollPosition