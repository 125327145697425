import { Controller } from "stimulus"

###
  This Stimulus controller dynamically updates the text and href attribute of an
  <a> tag based on a specified key in the browser's localStorage or sessionStorage.

  - The controller expects the target element (@element) to be an <a> tag.
  - It retrieves a key-value pair from the storage (defined via data attributes)
    and updates the link's text and href accordingly if the key exists.

  Example Usage:
  %a{
      href: community_jobs_url,
      data: {
        controller: 'search--return-to-search-results',

        'search--return-to-search-results-storage-key-value': 'lastJobSearchUrl',
        'search--return-to-search-results-storage-type-value': 'session',
        'search--return-to-search-results-hit-text-value': 'Return to your last job search'
      }
    } Return to the job directory

  Data Attributes:
  - `search--return-to-search-results-storage-key-value`: The storage key to look up.
  - `search--return-to-search-results-storage-type-value`: Type of storage ('local' or 'session').
  - `search--return-to-search-results-hit-text-value`: The text to display on the link when the key is found.

  Notes:
  - If the specified key does not exist in the storage, the link remains unchanged.
  - Valid storage types are 'local' and 'session'; invalid types will not retrieve any data.
###
export default class extends Controller
  @values =
    storageKey: String
    storageType: String
    hitText: String

  initialize: ->
    @update()

  update: ->
    if storedValue = @storageValueForKey()
      @element.innerText = @hitTextValue
      @element.href = storedValue

  storageValueForKey: ->
    return null unless @storageKeyValue

    if @storageTypeValue == 'local'
      localStorage.getItem(@storageKeyValue)

    else if @storageTypeValue == 'session'
      sessionStorage.getItem(@storageKeyValue)

    else
      null
